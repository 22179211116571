export function errorAuthenticatedPagePath(): string {
    return '/error-authenticated';
}

export function errorPagePath(): string {
    return '/error';
}

export function notFoundPagePath(): string {
    return '/not-found';
}

export function registrationPagePath(): string {
    return '/register';
}

export function notAuthorizedPagePath(): string {
    return '/not-authorized';
}

export function forbiddenPagePath(): string {
    return '/forbidden';
}

export function dashboardPagePath(): string {
    return '/dashboard';
}

export function faqPagePath(): string {
    return '/faq';
}

export function legalNoticePath(): string {
    return '/legal-notice';
}

export function thirdPartyLicensesPagePath(): string {
    return '/third-party-licenses';
}

export function landingPagePath(): string {
    return '/';
}

export function myProfilePagePath(): string {
    return '/my-profile';
}

export function postboxPagePath(): string {
    return '/postbox';
}

export function postboxPagePathWithContractId(contractId = ':contractId'): string {
    return `/contracts/${contractId}/postbox`;
}

export function amortizationTablePath(contractId = ':contractId'): string {
    return `/amortization-table/${contractId}`;
}

export function taxesTablePagePath(contractId = ':contractId'): string {
    return `/taxes-table/${contractId}`;
}

export function myRequestsPagePath(): string {
    return '/my-requests';
}

export function requestDocumentPath(contractId = ':contractId'): string {
    return `/request-document/${contractId}`;
}

export function earlySettlementPath(contractId = ':contractId'): string {
    return `/early-settlement/${contractId}`;
}

export function changeIbanPath(contractId = ':contractId'): string {
    return `${dashboardPagePath()}/${contractId}/change-iban`;
}

export function irapCalculationPath(contractId = ':contractId'): string {
    return `/irap-calculation/${contractId}`;
}

export function offlinePaymentPath(contractId = ':contractId'): string {
    return `${earlySettlementPath(contractId)}/offline-payment`;
}

export function loginAnalyticsPath(): string {
    return 'login';
}

export function registrationAnalyticsPath(): string {
    return 'register';
}

export function logoutPagePath(): string {
    return '/logout';
}

export function timeOutPagePath(): string {
    return '/time-out';
}
