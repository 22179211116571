import { AnyAction, combineReducers, createAction } from '@reduxjs/toolkit';
import bundledProductsDownloadLinksReducer from 'components/amortization-table/amortization-table-content/bundled-products-download-links/BundledProductsDownloadLinksSlice';
import amortizationTableHeaderReducer from 'components/amortization-table/AmortizationTableHeaderSlice';
import bundledProductsReducer from 'components/contracts/bundled-products/BundledProductSlice';
import contractsReducer from 'components/contracts/ContractsSlice';
import financialDetailsReducer from 'components/contracts/financial-details/FinancialDetailsSlice';
import generalContractDetailsReducer from 'components/contracts/general-contract-details/GeneralContractDetailsSlice';
import dashboardMarketingCardReducer from 'components/dashboard-marketing-card/DashboardMarketingCardSlice';
import forbiddenErrorReducer from 'components/error-pages/forbidden/ForbiddenErrorPageSlice';
import genericErrorReducer from 'components/error-pages/generic/GenericErrorPageSlice';
import notFoundErrorReducer from 'components/error-pages/not-found/NotFoundErrorPageSlice';
import unauthorizedErrorReducer from 'components/error-pages/unauthorized/UnauthorizedErrorPageSlice';
import faqReducer from 'components/faq/FaqSlice';
import greetingReducer from 'components/greeting/GreetingSlice';
import iconFooterReducer from 'components/icon-footer/IconFooterSlice';
import landingPageMarketingCardsReducer from 'components/landing-page-marketing-cards/LandingPageMarketingCardsSlice';
import landingPageTeaserReducer from 'components/landing-page-teaser/LandingPageTeaserSlice';
import logoutPageTeaserReducer from 'components/logout-page-teaser/LogoutPageTeaserSlice';
import timeOutPageTeaserReducer from 'components/time-out-page-teaser/TimeOutPageTeaserSlice';
import landingPageVideoReducer from 'components/landing-page-video/LandingPageVideoSlice';
import legalFooterReducer from 'components/legal-footer/LegalFooterSlice';
import legalNoticeReducer from 'components/legal-notice/LegalNoticeSlice';
import customerAddressReducer from 'components/my-profile/address-section/CustomerAddressSlice';
import contactDetailsReducer from 'components/my-profile/contact-section/ContactSectionSlice';
import identificationReducer from 'components/my-profile/identification-section/IdentificationSlice';
import postboxDocumentsReducer from 'components/postbox/PostboxDocumentsSlice';
import taxesTableHeaderReducer from 'components/taxes-table/TaxesTableHeaderSlice';
import thirdPartyLicensesReducer from 'components/third-party-licenses/ThirdPartyLicensesSlice';
import totalEarlySettlementReducer from 'components/total-early-settlement/TotalEarlySettlementDataSlice';
import irapCalculationReducer from 'components/irap-calculation/IrapCalculationDataSlice';
import vehicleDetailsReducer from 'components/vehicle-details/VehicleDetailsSlice';
import vehicleServiceDetailsReducer from 'components/contracts/vehicle-details-and-image/VehicleServiceDetailsSlice';
import vehicleImageReducer from 'components/contracts/vehicle-details-and-image/VehicleImageSlice';
import productRoutingReducer from 'components/dashboard-marketing-card/productRoutingSlice';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

const appReducer = combineReducers({
    router: connectRouter(history),
    vehicleDetails: vehicleDetailsReducer,
    greeting: greetingReducer,
    contracts: contractsReducer,
    generalContractDetails: generalContractDetailsReducer,
    bundledProducts: bundledProductsReducer,
    financialDetails: financialDetailsReducer,
    amortizationTableHeader: amortizationTableHeaderReducer,
    amortizationTableBundledProductsDownloadLinks: bundledProductsDownloadLinksReducer,
    taxesTableHeader: taxesTableHeaderReducer,
    identification: identificationReducer,
    customerAddress: customerAddressReducer,
    totalEarlySettlementData: totalEarlySettlementReducer,
    irapCalculation: irapCalculationReducer,
    contact: contactDetailsReducer,
    postboxDocuments: postboxDocumentsReducer,
    productRouting: productRoutingReducer,
    vehicleDetailsAndImage: combineReducers({
        vehicleServiceDetails: vehicleServiceDetailsReducer,
        vehicleImage: vehicleImageReducer,
    }),
    content: combineReducers({
        landingPageTeaser: landingPageTeaserReducer,
        logoutPageTeaser: logoutPageTeaserReducer,
        timeOutPageTeaser: timeOutPageTeaserReducer,
        landingPageVideo: landingPageVideoReducer,
        faq: faqReducer,
        genericError: genericErrorReducer,
        notFoundError: notFoundErrorReducer,
        forbiddenError: forbiddenErrorReducer,
        unauthorizedError: unauthorizedErrorReducer,
        landingPageMarketingCards: landingPageMarketingCardsReducer,
        dashboardMarketingCard: dashboardMarketingCardReducer,
        legalNotice: legalNoticeReducer,
        iconFooter: iconFooterReducer,
        legalFooter: legalFooterReducer,
        thirdPartyLicenses: thirdPartyLicensesReducer,
    }),
});

export type RootState = ReturnType<typeof appReducer>;

export const resetStore = createAction('store/reset');

const rootReducer = (state: RootState | undefined, action: AnyAction) => {
    if (action.type === resetStore.toString()) {
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;
