import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Fieldset, Form, Card } from '@vwfs-bronson/bronson-react';
import { Formik } from 'formik';
import { CpDataApi } from 'cp-xhr';
import {
    Accordion,
    AccordionItem,
    ValidatedInput,
    ValidatedCheckbox,
    preventSubmit,
    Spinner,
    useAnalyticsFormTracker,
    useAnalyticsActionTracker,
} from '@cp-shared-8/frontend-ui';
import { parseErrorResponse } from '@cp-shared-8/frontend-integration';
import {
    getRegistrationEndpoint,
    RegistrationDataPrivatePerson,
    RegistrationDataCompany,
    RegistrationError,
    Identification,
    getDeleteUserRegistryTransactionIdsEndpoint,
    RegistrationResponse,
} from '../../../../../../common';

import { registrationStep1FormValidationSchema } from '../../validation';
import { useTrackerOnBeforeUnload } from '../../../../../../hooks';
import { isEmpty } from 'lodash';

export type RegistrationFormType = 'private' | 'business';

type Props = {
    type: RegistrationFormType;
    legalNotice: string;
    identificationData?: Identification;
    isProspect: boolean;
    onSubmitSuccess?: Function;
    onSubmitError?: Function;
};

export const RegistrationStep1FormUi: React.FC<Props> = ({
    type,
    legalNotice,
    identificationData,
    isProspect,
    onSubmitSuccess,
    onSubmitError,
}) => {
    const { t, i18n } = useTranslation('registration');
    const [showCustomerId, setShowCustomerId] = useState(true);
    const [showLegalNotice, setShowLegalNotice] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { onAction: trackOnError } = useAnalyticsActionTracker('onConfirmIdentiyAuthFailed');
    const { onAction: trackOnSuccess } = useAnalyticsActionTracker('onConfirmIdentiyAuthSuccess');

    const { onAction } = useAnalyticsActionTracker('onFormValidationErrorConfirmIdentity');

    const { onTyping } = useAnalyticsFormTracker({
        startTyping: 'onStartTypingConfirmIdentity',
    });
    const setLastTouchedField = useTrackerOnBeforeUnload();

    type formProps = {
        name: string;
        surname: string;
        identityCode: string;
        customerId: string;
        contractNumber: string;
        confirmPrivacyPolicy: boolean;
        confirmLegalNotice: boolean;
    };

    const ifPresentOrEmpty = (value: string | undefined): string => {
        return value === '-' || value === undefined ? '' : String(value);
    };

    const getInitialValues = (isProspect: boolean, type: string) => {
        let initValues = {
            name: '',
            surname: '',
            identityCode: '',
            customerId: '',
            contractNumber: '',
            confirmPrivacyPolicy: false,
            confirmLegalNotice: false,
        };
        if (isProspect) {
            if (type === 'private') {
                initValues = {
                    ...initValues,
                    name: ifPresentOrEmpty(identificationData?.privateData?.firstName),
                    surname: ifPresentOrEmpty(identificationData?.privateData?.lastName),
                    identityCode: ifPresentOrEmpty(identificationData?.privateData?.taxCode),
                };
            } else if (type === 'business') {
                if (identificationData?.legalEntityData) {
                    initValues = {
                        ...initValues,
                        name: ifPresentOrEmpty(identificationData?.legalEntityData?.companyName),
                        identityCode: ifPresentOrEmpty(identificationData?.legalEntityData?.vatNumber),
                    };
                } else if (identificationData?.businessManData) {
                    initValues = {
                        ...initValues,
                        name: ifPresentOrEmpty(identificationData?.businessManData?.companyName),
                        identityCode: ifPresentOrEmpty(identificationData?.businessManData?.vatNumber),
                    };
                }
            }
        }
        return initValues;
    };

    const initValues = getInitialValues(isProspect, type);
    const getErrors = (errors: { [k: string]: string | undefined }) => Object.keys(errors).join(`, `);
    const initialListErrors =
        'name, surname, identityCode, customerId, contractNumber, confirmPrivacyPolicy, confirmLegalNotice';

    const handleSubmit = (values: formProps): void => {
        const isPrivateForm = type === 'private';
        const { name, surname, identityCode, customerId, contractNumber } = values;
        setIsSubmitting(true);
        const currentLanguage = i18n.languages[0];
        const requestBody: RegistrationDataCompany | RegistrationDataPrivatePerson = isPrivateForm
            ? {
                  firstName: name.trim(),
                  lastName: surname.trim(),
                  taxCode: identityCode.trim(),
                  useCustomerId: showCustomerId,
                  language: currentLanguage,
              }
            : {
                  companyName: name.trim(),
                  vatNumber: identityCode.trim(),
                  useCustomerId: showCustomerId,
                  language: currentLanguage,
              };
        if (customerId) {
            requestBody.customerId = parseInt(customerId);
        }
        if (contractNumber) {
            requestBody.contractNumber = parseInt(contractNumber);
        }
        CpDataApi.post(getRegistrationEndpoint(), requestBody)
            .then(async response => {
                setIsSubmitting(false);
                if (isProspect) {
                    await CpDataApi.delete(getDeleteUserRegistryTransactionIdsEndpoint());
                }
                if (onSubmitSuccess) {
                    const data: RegistrationResponse = response.data;
                    onSubmitSuccess(data);
                    trackOnSuccess();
                }
            })
            .catch(error => {
                setIsSubmitting(false);
                if (onSubmitError) {
                    onSubmitError(parseErrorResponse<RegistrationError>(error).code);
                }
                trackOnError();
            });
    };

    return (
        <>
            {isSubmitting && <Spinner fullPage={true} />}
            <Formik
                enableReinitialize
                initialValues={initValues}
                validationSchema={registrationStep1FormValidationSchema(t, type, showCustomerId)}
                onSubmit={handleSubmit}
            >
                {formik => (
                    <Form
                        onSubmit={preventSubmit}
                        onChange={() => {
                            onTyping(formik.errors, formik.touched);
                        }}
                    >
                        <Fieldset>
                            <Fieldset.Row>
                                <ValidatedInput
                                    label={t(`form.input-labels.${type}.name`)}
                                    name="name"
                                    testId={`${type}name`}
                                    onFocus={() => setLastTouchedField(t(`form.input-labels.${type}.name`))}
                                />
                            </Fieldset.Row>
                            {type === 'private' && (
                                <Fieldset.Row>
                                    <ValidatedInput
                                        label={t(`form.input-labels.${type}.surname`)}
                                        name="surname"
                                        testId="surname"
                                        onFocus={() => setLastTouchedField(t(`form.input-labels.${type}.surname`))}
                                    />
                                </Fieldset.Row>
                            )}
                            <Fieldset.Row>
                                <ValidatedInput
                                    label={t(`form.input-labels.${type}.identity-code`)}
                                    name="identityCode"
                                    testId={`${type}identityCode`}
                                    onFocus={() => setLastTouchedField(t(`form.input-labels.${type}.identity-code`))}
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                {showCustomerId ? (
                                    <ValidatedInput
                                        label={t('form.input-labels.customer-id')}
                                        hint={t('form.button-labels.use-contract-number')}
                                        onLinkClick={(): void => setShowCustomerId(false)}
                                        name="customerId"
                                        testId={`${type}customerId`}
                                        onFocus={() => setLastTouchedField(t('form.input-labels.customer-id'))}
                                    />
                                ) : (
                                    <ValidatedInput
                                        onLinkClick={(): void => setShowCustomerId(true)}
                                        testId="contractNumber"
                                        hint={t('form.button-labels.use-customer-id')}
                                        label={t('form.input-labels.contract-number')}
                                        name="contractNumber"
                                        onFocus={() => setLastTouchedField(t('form.input-labels.contract-number'))}
                                    />
                                )}
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedCheckbox
                                    label={
                                        <>
                                            {t('form.input-labels.accept-privacy-policy')}{' '}
                                            <Button
                                                href={t('form.button-labels.privacy-policy.link')}
                                                target={'_blank'}
                                                element="a"
                                                link={true}
                                            >
                                                {t('form.button-labels.privacy-policy.label')}
                                            </Button>
                                        </>
                                    }
                                    name="confirmPrivacyPolicy"
                                    testId={`${type}confirmPrivacyPolicy`}
                                    onFocus={() => setLastTouchedField(t('form.button-labels.privacy-policy'))}
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedCheckbox
                                    label={
                                        <>
                                            {t('form.input-labels.accept-legal-notice')}{' '}
                                            <Button
                                                onClick={(e): void => {
                                                    e.preventDefault();
                                                    setShowLegalNotice(!showLegalNotice);
                                                }}
                                                testId="showLegalNotice"
                                                element="a"
                                                link={true}
                                            >
                                                {t('form.button-labels.legal-notice')}
                                            </Button>
                                        </>
                                    }
                                    name="confirmLegalNotice"
                                    testId={`${type}confirmLegalNotice`}
                                    onFocus={() => setLastTouchedField(t('form.button-labels.legal-notice'))}
                                />
                            </Fieldset.Row>
                            {showLegalNotice && (
                                <Fieldset.Row testId="legalNotice">
                                    <Card element={'article'} scrollable={true}>
                                        <Accordion lazyRender={true}>
                                            <AccordionItem title={t('form.button-labels.legal-notice')}>
                                                <div
                                                    className={'u-text-left'}
                                                    dangerouslySetInnerHTML={{ __html: legalNotice }}
                                                />
                                            </AccordionItem>
                                        </Accordion>
                                    </Card>
                                </Fieldset.Row>
                            )}
                            <Fieldset.Row>
                                <Button
                                    full={true}
                                    testId={`${type}submitButton`}
                                    onClick={() => {
                                        formik.submitForm();
                                        if (!isEmpty(formik.errors) || isEmpty(formik.touched)) {
                                            const errorToString = getErrors(formik.errors).toString();
                                            if (!errorToString) {
                                                onAction(initialListErrors);
                                            } else onAction(getErrors(formik.errors));
                                        }
                                    }}
                                >
                                    {t('form.button-labels.submit')}
                                </Button>
                            </Fieldset.Row>
                        </Fieldset>
                    </Form>
                )}
            </Formik>
        </>
    );
};
